import React, {Component} from "react";

export class LandingNavbar extends Component {
    static displayName = LandingNavbar.name;

    constructor(props) {
        super(props);
        this.state = {
            shrinkNav: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        if (window.scrollY === 0) {
            this.setState({ shrinkNav: false });
        } else {
            this.setState({ shrinkNav: true });
        }
    }

    componentDidMount() {
        // Adding the scroll event listener when the component mounts
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        // Cleaning up the scroll event listener when the component unmounts
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        const { shrinkNav } = this.state;
        const navClass = `navbar navbar-expand-lg bg-secondary fixed-top ${shrinkNav ? "navbar-shrink" : ""}`;

        return (
            <nav className={navClass} id="mainNav">
                <div className="container">
                    <a className="navbar-brand" href="#page-top">
                        Thuis bij Frans
                    </a>
                    <button
                        className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        Menu
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item mx-0 mx-lg-1">
                                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="#about">
                                    Over ons
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="#portfolio">
                                    Activiteiten
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="#gallery">
                                    Gallerij
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="https://forms.gle/tW6NUuqAKA4UDPaS7">
                                    Avondeten bestellen
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}