import React, {Component} from "react";

export class DinerItem extends Component {
    render() {
        return (
            <div>
                <div className="fw-bold">
                    Voor bestellingen en informatie het gerecht van deze week:
                </div>
                <a className="lead" href="https://forms.gle/tW6NUuqAKA4UDPaS7">
                    NAAR HET BESTELFORMULIER
                </a>
                <hr/>
                Bij onze gezellige ontmoetingsplek op de W.C. Beeremanstraat
                1, ben je op vrijdag ook welkom voor een lekkere en gezonde
                maaltijd die heel betaalbaar is. We streven er naar om dit rond
                € 4,00 aan te kunnen bieden. Heb je een keer geen zin om te
                koken dan ben je ook hiervoor bij ons aan het juiste adres.
                Elke vrijdag staan we in onze keuken om een verrukkelijk
                gerecht te maken en dit is elke week weer iets anders.
                Je kunt dit vooraf bestellen en vervolgens tussen 15.00 en 16.00 komen
                ophalen.
            </div>
            
        );
    }
}