import React, {Component} from "react";

export class PortfolioItem extends Component {
    render() {
        const { itemImage, itemTitle, modalTitle, itemComponent  } = this.props;
        const modalTarget = "modal" + itemTitle;
        
        return (
            <div className="col-md-6 col-lg-3 mb-5">
                <div className="text-white">
                    <div
                        className="portfolio-item mx-auto"
                        data-bs-toggle="modal"
                        data-bs-target={"#" + modalTarget}
                    >
                        <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center">
                                Lees meer
                            </div>
                        </div>
                        <img
                            className="img-fluid rounded-5"
                            src={itemImage}
                            alt="..."
                        />
                        <div className="mt-2 text-center">
                            <h4>{itemTitle}</h4>
                        </div>
                    </div>
                </div>
                <div
                    className="portfolio-modal modal fade"
                    id={modalTarget}
                    tabIndex="-1"
                    aria-labelledby={modalTarget}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button
                                    className="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body text-center pb-5">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            {/* Portfolio Modal - Title*/}
                                            <h2
                                                className="portfolio-modal-title text-secondary text-uppercase mb-0"
                                            >
                                                {modalTitle}
                                            </h2>
                                            {/* Icon Divider*/}
                                            <div className="divider-custom">
                                                <div className="divider-custom-line"></div>
                                                <div className="divider-custom-icon">
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                <div className="divider-custom-line"></div>
                                            </div>
                                            {/* Portfolio Modal - Text*/}
                                            <div className="mb-4 lead">
                                                {itemComponent}
                                            </div>
                                            <button className="btn btn-primary" data-bs-dismiss="modal">
                                                <i className="fas fa-xmark fa-fw"></i>
                                                Sluit venster
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}