import React, {Component} from "react";

export class GardenItem extends Component {
    render() {
        return (
            <div>
                Achter ons huis hebben wij een mooie moestuin waar onze
                jongeren actief deelnemen aan het verbouwen van heerlijke
                groenten en kruiden. Ze leren niet alleen over tuinieren en
                plantverzorging maar genieten ook van de rustgevende en
                therapeutische voordelen van het werken met je handen in de
                aarde. Daarnaast is het een hele fijne manier om in verbinding
                te komen met jezelf.
                <hr />
                <div className="fw-bold">Wat groeit er?</div>
                In onze moestuin groeit een verscheidenheid aan gewassen, van
                sla, tomaten, pompoenen, broccoli tot aromatische gewassen
                zoals rozemarijn en munt. Wanneer er een overschot is aan
                deze producten dan worden deze voor ons huis gratis
                aangeboden om mee te nemen. Zo blijft het toch in de buurt.
                <hr />
                <div className="fw-bold">Kan ik meehelpen?</div>
                Wij vinden het heel leuk als onze buurtgenoten en eventuele
                vrijwilligers komen helpen bij onze moestuinactiviteiten. Het
                maakt niet uit of je nu ervaren bent of het gewoon wil leren,
                iedereen is welkom. Als je interesse hebt neem dan gerust
                contact met ons op.

                Samen kunnen we van onze moestuin een bloeiende
                gemeenschapsruimte maken!
            </div>
        );
    }
}