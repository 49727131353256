import React, {Component} from "react";

export class EventsItem extends Component {
    render() {
        return (
            <div>
                <div className="fw-bold">
                    Ruimtes beschikbaar voor een wederzijdse dienst
                </div>
                Ruimtes beschikbaar voor een wederzijdse dienst
                Als je op zoek bent naar een ruimte voor een speciale
                gelegenheid dan kun je ook bij ons terecht. Wij hebben diverse
                ruimtes beschikbaar die goed te gebruiken zijn voor
                vergaderingen, workshops etc. In onze grote tuin kun je heel
                goed buurtbijeenkomsten houden of een leuke BBQ
                organiseren. Mocht je een van onze ruimtes willen gebruiken
                stuur dan maar even een mail naar info@ontmoetingsplekfrans.nl en we kijken dan
                samen naar de mogelijkheden.
                <hr />
                <div className="fw-bold">
                    Evenementen en catering
                </div>
                En als je van plan bent om een geweldig evenement te
                organiseren, dan kunnen wij je ook goed helpen. Wist je
                bijvoorbeeld al dat wij hele lekkere verse fruitsappen maken die
                jouw evenement net wat extra’s geeft. Natuurlijk kunnen wij
                ook andere hapjes regelen. Laat ons even weten wat je nodig
                hebt en vraag een offerte aan.
            </div>
        );
    }
}