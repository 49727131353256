import React, {Component} from "react";

export class Gallery extends Component {
    render(){
        const { imagesPath, imageFilenames } = this.props;
        return (
            
            <div className="page-section container">
                <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
                    Foto's van onze ontmoetingsplek!
                </h2>
                
                {/* Icon Divider*/}
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon">
                        <i className="fas fa-star"></i>
                    </div>
                    <div className="divider-custom-line"></div>
                </div>
                
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7">
                            
                            <div id="galleryCarousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    {imageFilenames.map((imageFilename, index) => (
                                        <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                                            <img src={`${imagesPath}${imageFilename}`} className="d-block w-100 rounded-5" alt="..."/>
                                        </div>
                                    ))}
                                </div>
                                
                                <button className="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                
                                <button className="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

