import {Component} from "react";

export class Masthead extends Component {
    static displayName = Masthead.name;

    render() {
        return (
            <header className="masthead bg-primary text-white text-center">
                <div className="container d-flex align-items-center flex-column">
                    <img
                        className="masthead-avatar mb-5"
                        src="assets/img/house_image.svg"
                        alt="..."
                    />
                    <h1 className="masthead-heading mb-0">Thuis bij Frans</h1>
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                            <i className="fa-solid fa-clover"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>
                    <p className="masthead-subheading font-weight-light mb-0">
                        De gezelligste ontmoetingsplek in Veenendaal!
                    </p>
                </div>
            </header>
        );
    }
}