import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './style/custom.css';

export default class App extends Component {
  static displayName = App.name;
    
  render() {
    const home = window.location.pathname === '/';
    const Wrapper = home ? React.Fragment : Layout;
    return (
      <Wrapper>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Wrapper>
    );
  }
}
