import React, {Component} from "react";

export class Footer extends Component {
    static displayName = Footer.name;
    
    render() {
        return (
            <footer className="footer text-center">
                <div className="container">
                    <div className="row">
                        {/* Footer Location*/}
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h4 className="text-uppercase mb-4">Ons adres</h4>
                            <p className="lead mb-0">
                                W.C. Beeremansstraat 1
                                <br />
                                3904EV, Veenendaal
                                <br /><br />
                                <a
                                    className="btn btn-outline-light btn-social mx-1"
                                    href="https://shorturl.at/pPSY7"
                                >
                                    <i className="fa-solid fa-location-dot"></i>
                                </a>
                            </p>
                        </div>
                        {/* Footer Social Icons*/}
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h4 className="text-uppercase mb-4">Volg ons!</h4>
                            <a 
                                className="btn btn-outline-light btn-social mx-1" 
                                href="#!"
                            >
                                <i className="fab fa-fw fa-facebook-f"></i>
                            </a>
                            <a 
                                className="btn btn-outline-light btn-social mx-1" 
                                href="#!"
                            >
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}