import React, {Component} from "react";
import {DinerItem} from "./portfolio/DinerItem";
import {EventsItem} from "./portfolio/EventsItem";
import {GardenItem} from "./portfolio/GardenItem";
import {PodcastItem} from "./portfolio/PodcastItem";
import {PortfolioItem} from "./portfolio/PortfolioItem";

export class Portfolio extends Component {
    static displayName = Portfolio.name;

    render() {
        // Get path from props for the images
        const { imagesPath } = this.props;

        // Get element for inside the portfolio items
        const dinerItem= <DinerItem />;
        const eventsItem = <EventsItem />;
        const gardenItem = <GardenItem />;
        const podcastItem = <PodcastItem />;

        return (


            <div className="page-section portfolio bg-primary mb-0">
                <div className="container">

                    {/* Portfolio Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-white">
                        Activiteiten
                    </h2>

                    {/* Icon Divider*/}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                            <i className="fa-solid fa-people-roof"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                    {/* Portfolio Grid Items*/}
                    <div className="row justify-content-center">

                        <PortfolioItem
                            itemImage={`${imagesPath}avondeten.png`}
                            itemTitle="Avondeten"
                            modalTitle="Lekkere en gezonde maaltijden"
                            itemComponent={dinerItem}
                        />
                        <PortfolioItem
                            itemImage={`${imagesPath}evenementen.png`}
                            itemTitle="Evenementen"
                            modalTitle="Huur en evenementen"
                            itemComponent={eventsItem}
                        />
                        <PortfolioItem
                            itemImage={`${imagesPath}moestuin.png`}
                            itemTitle="Moestuin"
                            modalTitle="Welkom in onze Buurt-Moestuin"
                            itemComponent={gardenItem}
                        />
                        <PortfolioItem
                            itemImage={`${imagesPath}ontmoetingsplek_1.png`}
                            itemTitle="Podcast"
                            modalTitle="Podcast door onze jongeren"
                            itemComponent={podcastItem}
                        />

                    </div>
                </div>
            </div>
        );
    }
}
