import React, {Component} from "react";

export class PodcastItem extends Component {
    render() {
        return (
            <div>
                Om buurtgenoten nog beter te leren kennen en mooie verhalen
                te horen, hebben we nog iets leuks bedacht. Onze jongeren
                hebben namelijk hun creatieve energie omgezet in een podcast
                die je de lokale sfeer in de wijk laat meemaken.
                <br /><br />
                Regelmatig komen ze samen met plaatselijke winkeliers en
                buurtgenoten om gesprekken te voeren, grappige anekdotes te
                delen en verhalen te vertellen die onze buurt uniek maken.
                Het is een geweldige manier om elkaar beter te leren kennen en te
                ontdekken wat onze buurt te bieden heeft.
                <br /><br />
                Misschien heb je wel interesse om zelf deel te nemen aan onze
                podcast? Of misschien heb je een verhaal te delen. We staan
                altijd open voor nieuwe gasten en verhalen, dus aarzel niet om
                ons te benaderen.
                Het is echt een fantasische manier om de banden in de buurt
                te versterken en te laten zien dat we allemaal deel uitmaken
                van ’t Franse Gat.
            </div>
        );
    }
}