import React, {Component} from "react";

export class About extends Component {
    static displayName = About.name;

    render() {
        return (
            <div className="page-section">
                <div className="container">
                    {/* About Section Heading*/}
                    <h2
                        className="page-section-heading text-center text-uppercase text-secondary mb-0"
                    >
                        Over ons
                    </h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                            <i className="fas fa-heart"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* About Section Content*/}
                    <div className="row">
                        <div className="col-lg-4 ms-auto">
                            <p className="lead">
                                Midden in het hart van het mooie Franse Gat zijn wij een
                                ontmoetingsplek voor iedereen in de buurt. Van jong tot oud,
                                van ondernemer tot vrijwilliger. Iedereen die vandaag de dag
                                even met een kop koffie in een warme, gezellige plek op adem
                                wil komen. Wij willen graag dé plek in de wijk zijn waar iedereen zich
                                welkom voelt en kan kletsen, lachen en nieuwe mensen kan
                                ontmoeten. Dus, als je je buurman of buurvrouw beter wilt
                                leren kennen, of als je gewoon behoefte hebt aan wat
                                gezelschap, schuif gerust bij ons aan. We hebben altijd een
                                plekje voor je vrij. En natuurlijk mag je ook een hulpvraag
                                stellen. We zijn er ook om elkaar te helpen!
                            </p>
                        </div>
                        <div className="col-lg-4 me-auto">
                            <p className="lead">
                                Wij zijn een zorginstelling met de naam For Recovery &
                                Dialogue, Onze stichting is al 7 jaar in Veenendaal gevestigd en
                                helpt jong volwassenen om een betere versie van zichzelf te
                                worden. Naast het bieden van ambulante zorg, begeleiden we
                                ook jongeren in een Beschermd Woonvorm waar we
                                momenteel plek hebben voor 12 jongeren elders in
                                Veenendaal. Deze jongeren staan te popelen om in hun
                                gemaakte studio podcasts te maken, verse sappen en koffie te
                                serveren en om de eenzaamheid onder ouderen te verkleinen
                                door voor hen te koken en samen verhalen op te halen.
                            </p>
                        </div>
                        <hr />
                        <p className="lead text-center fw-bold">
                            Wij zijn elke dinsdag 13:00 – 16:00 & vrijdag 12:00 - 16:00 uur geopend!
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
