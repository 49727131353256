import React, {Component, useEffect} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/LandingPage.css';
import {LandingNavbar} from "./landing/LandingNavbar";
import {About} from "./landing/About";
import {Masthead} from "./landing/Masthead";
import {Portfolio} from "./landing/Portfolio";
import {Footer} from "./landing/Footer";
import {Gallery} from "./landing/Gallery";


export class Home extends Component {
    static displayName = Home.name;
    
    // Gallery/Portfolio images path
    imagesFilePath = "assets/img/portfolio/";
    
    // Gallery images filenames
    galleryImages = [
        "avondeten.png",
        "evenementen.png",
        "moestuin.png",
        "ontmoetingsplek_1.png",
        "ontmoetingsplek_2.png"
    ];
    
    render() {
        return (
            <div>

                {/* Landing page Navbar component */}
                <LandingNavbar/>

                {/* Masthead */}
                <Masthead />

                {/* About Section*/}
                <section id="about">
                    <About />
                </section>

                {/* Portfolio Section*/}
                <section id="portfolio">
                    <Portfolio imagesPath={this.imagesFilePath} />
                </section>

                {/* Contact Section*/}
                <section id="gallery">
                    <Gallery imagesPath={this.imagesFilePath} imageFilenames={this.galleryImages}  />
                </section>

                {/* Footer*/}
                <Footer />

            </div>
        );
    }
}

